/**
 * @format
 */
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import isEmpty from 'lodash.isempty';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDBadge from 'components/MDBadge';
import DataTable from 'examples/Tables/DataTable';
import MDButton from 'components/MDButton';
import Loader from 'react-loader';
import {CandidatePopupPage} from '../containers';
import Icon from '@mui/material/Icon';
import InfoIcon from '@mui/icons-material/Info';
// Material Dashboard 2 React context
import {useMaterialUIController} from 'context';
const JobView = (props) => {
  const [controller] = useMaterialUIController();
  const {darkMode} = controller;

  let {job, jobId, fetchJobDetails, loading} = props;
  const [showComponent, setShowComponent] = useState(false);
  const [candidateId, setCandidateId] = useState('');

  const handleButtonClick = () => {
    setShowComponent(true);
  };

  useEffect(() => {
    fetchJobDetails(jobId);
  }, []);

  if (isEmpty(job) || loading) {
    return (
      <div style={{textAlign: 'center', padding: '300px'}}>
        <Loader
          lines={13}
          length={20}
          width={8}
          radius={24}
          corners={1}
          rotate={0}
          direction={1}
          color='#000'
          speed={1}
          trail={60}
          shadow={false}
          hwaccel={false}
          className='spinner'
          zIndex={2e9}
          top='50%'
          left='50%'
          scale={1.0}
          loadedClassName='loadedContent'
        />
      </div>
    );
  }

  const row = job?.tempId?.map((data, i) => ({
    index: (
      <MDTypography
        component='a'
        href='#'
        variant='caption'
        color='text'
        fontWeight='medium'>
        {i + 1}
      </MDTypography>
    ),
    currentTitle: (
      <MDTypography
        component='a'
        href='#'
        variant='caption'
        color='text'
        fontWeight='medium'>
        {data.currentTitle}
      </MDTypography>
    ),
    firstName: (
      <MDTypography
        component='a'
        href='#'
        variant='caption'
        color='text'
        fontWeight='medium'>
        {data.firstName}
      </MDTypography>
    ),
    lastName: (
      <MDTypography
        component='a'
        href='#'
        variant='caption'
        color='text'
        fontWeight='medium'>
        {data.lastName}
      </MDTypography>
    ),
    email: (
      <MDTypography
        component='a'
        href='#'
        variant='caption'
        color='text'
        fontWeight='medium'>
        {data.email}
      </MDTypography>
    ),
    experience: (
      <MDTypography
        component='a'
        href='#'
        variant='caption'
        color='text'
        fontWeight='medium'>
        {data.overallExperience}
      </MDTypography>
    ),
    status: (
      <MDBox ml={-1}>
        <MDBadge
          badgeContent={data.candidateStatusId.statusName}
          color=''
          variant='gradient'
          size='sm'
        />
      </MDBox>
    ),

    action: (
      <div>
        <Link
          to={`/candidates/candidate/${data?._id}/view`}
          onClick={(e) => {
            e.stopPropagation();
          }}>
          <MDButton variant='text' color={darkMode ? 'white' : 'dark'}>
            <Icon style={{color: 'gray', opacity: 1}}>remove_red_eye</Icon>
          </MDButton>
        </Link>
        <MDButton
          variant='text'
          color={darkMode ? 'white' : 'dark'}
          onClick={(e) => {
            e.stopPropagation();
            handleButtonClick();
            setCandidateId(data?._id);
          }}>
          {/* <Icon style={{color: 'gray', opacity: 1}}>remove_red_eye</Icon> */}
          <InfoIcon />
        </MDButton>
      </div>
    ),
  }));

  const finalData = {
    columns: [
      {Header: 'S.No', accessor: 'index', align: 'center'},
      {Header: 'title', accessor: 'currentTitle', align: 'center'},
      {Header: 'first name', accessor: 'firstName', align: 'center'},
      {Header: 'last name', accessor: 'lastName', align: 'center'},
      {Header: 'email', accessor: 'email', align: 'center'},
      {Header: 'experience', accessor: 'experience', align: 'center'},
      {Header: 'candidate status', accessor: 'status', align: 'center'},
      {Header: 'action', accessor: 'action', align: 'center'},
    ],
    rows: row,
  };

  const {columns, rows} = finalData;

  const url1 = new URL('https://recruit.naukri.com/homePage/index');

  url1.searchParams.set('jobId', jobId);

  return (
    <div className='layout-top-spacing'>
      <div className='row layout-spacing'>
        <div className='col-lg-12'>
          <div className='statbox widget box box-shadow'>
            <div className='widget-header'>
              <div className='row'>
                <div className='col-xl-8 col-md-8 col-sm-8 col-8 '>
                  <h4>View Job Information</h4>
                </div>
                <div className='col-xl-4 col-md-4 col-sm-4 col-4 text-right col-sm add-employee-cont'>
                  <Link
                    // to='/jobs'
                    onClick={() => props.history.goBack()}
                    className='btn btn-outline-primary my-2'>
                    Back
                  </Link>
                </div>
              </div>
            </div>
            <div className='col-xl-8 col-lg-6 col-md-7 col-sm-12 layout-top-spacing job-col-width'>
              <div className='work-experience layout-spacing '>
                <div className='widget-content widget-content-area sm-margin-left'>
                  <h3 className=''>Basic info</h3>

                  <div className='timeline-alter'>
                    <div className='item-timeline'>
                      <div className='t-meta-date'>
                        <p className=''>Client Name </p>
                      </div>
                      <div className='t-dot'></div>
                      <div className='t-text'>
                        <p>{job.client} </p>
                      </div>
                    </div>
                  </div>

                  <div className='timeline-alter'>
                    <div className='item-timeline'>
                      <div className='t-meta-date'>
                        <p className=''>Job Title</p>
                      </div>
                      <div className='t-dot'></div>
                      <div className='t-text'>
                        <p>{job.jobName}</p>
                      </div>
                    </div>
                  </div>
                  <div className='timeline-alter'>
                    <div className='item-timeline'>
                      <div className='t-meta-date'>
                        <p className=''>Job Type</p>
                      </div>
                      <div className='t-dot'></div>
                      <div className='t-text'>
                        <p>{job.jobtype} </p>
                      </div>
                    </div>
                  </div>
                  <div className='timeline-alter'>
                    <div className='item-timeline'>
                      <div className='t-meta-date'>
                        <p className=''>Experience</p>
                      </div>
                      <div className='t-dot'></div>
                      <div className='t-text'>
                        <p>
                          {job.fromExperienceRequiredInYears} -{' '}
                          {job.toExperienceRequiredInYears} years
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='timeline-alter'>
                    <div className='item-timeline'>
                      <div className='t-meta-date'>
                        <p className=''>Job Assigned to</p>
                      </div>
                      <div className='t-dot'></div>
                      <div className='t-text'>
                        <p>{job.assign.join(', ')}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='work-experience layout-spacing '>
                <div className='widget-content widget-content-area sm-margin-left'>
                  <h3 className=''>Job Description</h3>
                  <div className='timeline-alter'>
                    <div className='item-timeline'>
                      <div
                        style={{
                          fontSize: '13px',
                          fontFamily: 'Roboto',
                          color: '#515365',
                          fontWeight: '500',
                        }}
                        dangerouslySetInnerHTML={{
                          __html: job.jobdescription,
                        }}></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='work-experience layout-spacing '>
                <div className='widget-content widget-content-area sm-margin-left'>
                  <h3 className=''>Internal Information</h3>
                  <div className='timeline-alter'>
                    <div className='item-timeline'>
                      <div
                        style={{
                          fontSize: '13px',
                          fontFamily: 'Roboto',
                          color: '#515365',
                          fontWeight: '500',
                        }}
                        dangerouslySetInnerHTML={{
                          __html: job.internalInfo,
                        }}></div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='work-experience layout-spacing '>
                <div className='widget-content widget-content-area sm-margin-left'>
                  <h3 className=''>Other info</h3>
                  <div className='timeline-alter'>
                    <div className='item-timeline'>
                      <div className='t-meta-date'>
                        <p className=''>Gender</p>
                      </div>
                      <div className='t-dot'></div>
                      <div className='t-text'>
                        <p>{job.gender} </p>
                      </div>
                    </div>
                  </div>
                  <div className='timeline-alter'>
                    <div className='item-timeline'>
                      <div className='t-meta-date'>
                        <p className=''>Location</p>
                      </div>
                      <div className='t-dot'></div>
                      <div className='t-text'>
                        <p>
                          {' '}
                          {job.Location === 'Remote'
                            ? `${job.Location}`
                            : job.Location === 'Office'
                            ? `${job.officelocation}`
                            : job.Location === 'Hybrid'
                            ? `Hybrid (${job.Hybridlocation})`
                            : 'Remote'}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='work-experience layout-spacing '>
                <div className='widget-content widget-content-area sm-margin-left'>
                  <h3 className=''>Additional Questions</h3>
                  {job.questions.map((question, i) => {
                    return (
                      <>
                        <div class='jobs-questions' key={i}>
                          <div className='timeline-alter'>
                            <div className='item-timeline'>
                              <div className='t-meta-date'>
                                <p className=''>Question {i + 1}</p>
                              </div>
                              <div className='t-dot'></div>
                              <div className='t-text'>
                                <p>
                                  {question.isRequired && (
                                    <span style={{color: 'red'}}>*</span>
                                  )} {question.questionName}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>

              {job.tempId.length > 0 ? (
                <div className='layout-top-spacing'>
                  <div className='row layout-spacing'>
                    <div className='col-lg-12'>
                      <div className='statbox widget box box-shadow'>
                        <div className='widget-header'>
                          <div className='row'>
                            <div className='col-xl-8 col-md-8 col-sm-8 col-8'>
                              <h4>Applied Candidates for {job.jobName}</h4>
                            </div>
                          </div>
                        </div>

                        <MDBox pt={6} pb={3}>
                          <Grid container spacing={6}>
                            <Grid item xs={12}>
                              <Card>
                                <MDBox
                                  mx={2}
                                  mt={-3}
                                  py={3}
                                  px={2}
                                  variant='gradient'
                                  bgColor='info'
                                  borderRadius='lg'
                                  coloredShadow='info'>
                                  <MDTypography variant='h6' color='white'>
                                    Candidates
                                  </MDTypography>
                                </MDBox>
                                <MDBox pt={3}>
                                  <DataTable
                                    table={{columns, rows}}
                                    isSorted={false}
                                    count={10}
                                    currentPage={1}
                                    entriesPerPage={false}
                                    showTotalEntries={false}
                                    noEndBorder
                                    onRowClick={(row) => {
                                      const index = row.index;
                                      const id = job.tempId[index]._id;
                                      props.history.push(
                                        `/candidates/candidate/${id}/view`,
                                      );
                                    }}
                                  />
                                </MDBox>
                              </Card>
                            </Grid>
                          </Grid>
                        </MDBox>
                        {showComponent && (
                          <CandidatePopupPage
                            showComponent={showComponent}
                            candidateId={candidateId}
                            jobId={jobId}
                            setShowComponent={setShowComponent}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}

              <div className='work-experience layout-spacing '>
                <div className='widget-content widget-content-area sm-margin-left'>
                  <h3 className=''>Naukri Portal</h3>
                  <div className='timeline-alter'>
                    <div className='item-timeline'>
                      <div className='t-meta-date'>
                        <p className=''>Link Here</p>
                      </div>
                      <div className='t-dot'></div>
                      <div className='t-text'>
                        <p>
                          <a
                            target='_blank'
                            href={job.jobStatus === 'active' && url1.href}>
                            Login To Naukri
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export {JobView};
