/**
 * @format
 */
import {connect} from 'react-redux';
import getObject from 'lodash.get';

import {JobView} from '../components';
import {fetchJobDetails} from '../actions';

const mapStateToProps = (state, ownProps) => {
  
  const jobId = getObject(ownProps, 'match.params.id', '');

  return {
    job: state.jobView.job,
    jobId,
  };
};

const JobViewPage = connect(mapStateToProps, {
  fetchJobDetails,
})(JobView);

export {JobViewPage};
