/**
 * @format
 */
import React from 'react';
import {useRouteMatch, Switch, Route} from 'react-router-dom';
import {CETempPage} from 'features/CETemp';
import {TempViewPage} from 'features/TempView';
import {TempListPage} from '../containers';
import {BulkUploadPage} from '../containers';
import {ErrorPage} from 'components';
const TempsPageView = () => {
  const {path} = useRouteMatch();

  return (
    <div className='layout-px-spacing'>
      <Switch>
        <Route path={path} exact component={TempListPage} />
        <Route path={`${path}/add`} component={CETempPage} />
        <Route exact path={`${path}/candidate/:id`} component={CETempPage} />
        <Route path={`${path}/candidate/:id/view`} component={TempViewPage} />
        <Route path={`${path}/bulk_upload`} component={BulkUploadPage} />
        <Route component={ErrorPage} />
      </Switch>
    </div>
  );
};

export {TempsPageView};
