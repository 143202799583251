/**
 * @format
 */
import {
  SET_TEMPS,
  TOGGLE_TEMP_LIST_LOADING,
  CHANGE_TEMP_CURRENT_PAGE,
  ADD_TEMP,
  SET_FILTERS,
  FETCH_ALL_STATUS,
  FETCH_ALL_SOURCE_TYPE
} from '../types';

const INITIAL_STATE = {
  temps: [],
  loading: false,
  status: [],
  sourceType: [],
  currentPage: 1,
  total: 0,
  filters: {
    candidateStatusId: 'All',
  },
};

export const TempListReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TOGGLE_TEMP_LIST_LOADING:
      return {
        ...state,
        loading: action.value,
      };
    case SET_TEMPS:
      return {
        ...state,
        temps: action.value,
        total: action.total,
      };
    case ADD_TEMP:
      return {
        ...state,
        temps: [...state.temps, action.value],
      };
    case FETCH_ALL_STATUS:
      return {
        ...state,
        status: action.value,
      };
      case FETCH_ALL_SOURCE_TYPE:
      return {
        ...state,
        sourceType: action.value,
      };
    case CHANGE_TEMP_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.value,
      };
    case SET_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.key]: action.value,
        },
      };
    default:
      return state;
  }
};
