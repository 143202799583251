/**
 * @format
 */
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {candidateStatusOptionList} from 'config';
import {ButtonDropdown, Table} from 'components';
import {confirmAlert} from 'globalValues';
import MDButton from 'components/MDButton';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Loader from 'react-loader';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDBadge from 'components/MDBadge';
import DataTable from 'examples/Tables/DataTable';
import Icon from '@mui/material/Icon';
import {useMaterialUIController} from 'context';
import {featureToEndpointMapping} from 'config/mapRoutesToFeature';
import {ElasticSearchPage} from './elasticSearch';
import {ElasticSearch} from '../containers';
import DropdownList from './listDropdown';

const TempListPageView = (props) => {
  const [controller] = useMaterialUIController();
  const {darkMode} = controller;
  const [showComponent, setShowComponent] = useState(false);

  const {
    totalItems,
    features,
    currentPage,
    loading,
    filters,
    status,
    temp = 'Candidates',
  } = props;

  useEffect(() => {
    props.fetchAllTemps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading && status) {
    return (
      <div style={{textAlign: 'center', padding: '300px'}}>
        <Loader
          lines={13}
          length={20}
          width={8}
          radius={24}
          corners={1}
          rotate={0}
          direction={1}
          color='#000'
          speed={1}
          trail={60}
          shadow={false}
          hwaccel={false}
          className='spinner'
          zIndex={2e9}
          top='50%'
          left='50%'
          scale={1.0}
          loadedClassName='loadedContent'
        />
      </div>
    );
  }
  const row = props.temps.map((data, i) => ({
    index: (
      <MDTypography
        component='a'
        href='#'
        variant='caption'
        color='text'
        fontWeight='medium'>
        {i + 1}
      </MDTypography>
    ),
    currentTitle: (
      <MDTypography
        component='a'
        href='#'
        variant='caption'
        color='text'
        fontWeight='medium'>
        {data?.currentTitle}
      </MDTypography>
    ),
    firstName: (
      <MDTypography
        component='a'
        href='#'
        variant='caption'
        color='text'
        fontWeight='medium'>
        {data?.firstName}
      </MDTypography>
    ),
    lastName: (
      <MDTypography
        component='a'
        href='#'
        variant='caption'
        color='text'
        fontWeight='medium'>
        {data?.lastName}
      </MDTypography>
    ),
    email: (
      <MDTypography
        component='a'
        href='#'
        variant='caption'
        color='text'
        fontWeight='medium'>
        {data?.email}
      </MDTypography>
    ),
    experience: (
      <MDTypography
        component='a'
        href='#'
        variant='caption'
        color='text'
        fontWeight='medium'>
        {data?.overallExperience}
      </MDTypography>
    ),
    status: (
      <MDBox ml={-1}>
        <MDBadge
          badgeContent={
            data?.candidateStatusId?.statusName || data?.candidateStatus
          }
          color=''
          variant='gradient'
          size='sm'
        />
      </MDBox>
    ),

    action: (
      <div>
        <Link
          to={`/candidates/candidate/${data?._id}`}
          onClick={(e) => {
            e.stopPropagation();
          }}>
          <MDButton variant='text' color={darkMode ? 'white' : 'dark'}>
            <Icon>edit</Icon>
          </MDButton>
        </Link>
        <MDButton
          variant='text'
          color='error'
          onClick={(e) => {
            e.stopPropagation();
            confirmAlert({
              title: 'Are you sure?',
              message: "You won't be able to revert this!",
              onDelete: () => props.deleteTemp(data._id),
            });
          }}>
          <Icon>delete</Icon>
        </MDButton>
        <Link
          to={`/candidates/candidate/${data?._id}/view`}
          onClick={(e) => {
            e.stopPropagation();
          }}>
          <MDButton variant='text'>
            <Icon style={{color: 'gray', opacity: 1}}>remove_red_eye</Icon>
          </MDButton>
        </Link>
      </div>
    ),
  }));

  const finalData = {
    columns: [
      {Header: 'S.No', accessor: 'index', align: 'center'},
      {Header: 'title', accessor: 'currentTitle', align: 'center'},
      {Header: 'first name', accessor: 'firstName', align: 'center'},
      {Header: 'last name', accessor: 'lastName', align: 'center'},
      {Header: 'email', accessor: 'email', align: 'center'},
      {Header: 'experience', accessor: 'experience', align: 'center'},
      {Header: 'candidate status', accessor: 'status', align: 'center'},
      {Header: 'action', accessor: 'action', align: 'center'},
    ],
    rows: row,
  };

  const {columns, rows} = finalData;

  return (
    <div className='layout-top-spacing'>
      <div className='row layout-spacing'>
        <div className='col-lg-12'>
          <div className='statbox widget box box-shadow'>
            <div className='widget-header'>
              <div className='row'>
                <div className='col-xl-8 col-md-8 col-sm-8 col-8'>
                  <h4>
                    Showing {props.temps?.length} of {totalItems}
                  </h4>
                </div>
                <div className='col-xl-4 col-md-4 col-sm-4 col-4 text-right col-sm add-employee-cont'>
                  {/* <ButtonDropdown
                    onSelectItem={(val) => {
                      props.setFilters('candidateStatus', val);
                      props.fetchAllTemps();
                    }}
                    selected={filters.candidateStatus}
                    items={[
                      {label: 'All', value: 'All'},
                      ...candidateStatusOptionList,
                    ]}
                  /> */}
                  <ButtonDropdown
                    onSelectItem={(val) => {
                      props.setFilters('candidateStatusId', val);
                      props.fetchAllTemps();
                    }}
                    selected={filters.candidateStatusId}
                    items={[{statusName: 'All', _id: 'All'}, ...status]}
                  />
                  {/* {features.includes(featureToEndpointMapping['/documents/bulkcvupload']) && <Link
                    to='/candidates/bulk_upload'
                    className='btn btn-outline-primary my-2'>
                    Bulk Upload
                  </Link>} */}

                  {/* <button onClick={handleButtonClick}>Elastic Search</button> */}
                  <DropdownList
                    showComponent={showComponent}
                    setShowComponent={setShowComponent}
                  />
                </div>
              </div>
            </div>
            {showComponent && (
              <ElasticSearch
                showComponent={showComponent}
                setShowComponent={setShowComponent}
              />
            )}
            {props.temps.length === 0 ? (
              <h2 style={{textAlign: 'center', padding: '200px'}}>
                There is no listing in this profile.
              </h2>
            ) : (
              <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                  <Grid item xs={12}>
                    <Card>
                      <MDBox
                        mx={2}
                        mt={-3}
                        //py={4}
                        p={2}
                        variant='gradient'
                        bgColor='info'
                        color='white'
                        borderRadius='lg'
                        coloredShadow='info'>
                        Candidates
                      </MDBox>
                      <MDBox pt={3}>
                        <DataTable
                          table={{columns, rows}}
                          isSorted={false}
                          count={totalItems}
                          currentPage={props?.currentPage}
                          handlePageChange={props.changeCurrentPage}
                          entriesPerPage={false}
                          showTotalEntries={false}
                          noEndBorder
                          pagination={{
                            showTotalEntries: true,
                            noEndBorder: true,
                            count: totalItems,
                          }}
                          onRowClick={(row) => {
                            const index = row.index;
                            const id = props.temps[index]._id;
                            props.history.push(
                              `/candidates/candidate/${id}/view`,
                            );
                          }}
                        />
                      </MDBox>
                    </Card>
                  </Grid>
                </Grid>
              </MDBox>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

TempListPageView.propTypes = {
  fetchAllTemps: PropTypes.func,
  deleteTemp: PropTypes.func,
  temps: PropTypes.array,
  filters: PropTypes.object,
  history: PropTypes.object,
  totalItems: PropTypes.number,
  currentPage: PropTypes.number,
  loading: PropTypes.bool,
  changeCurrentPage: PropTypes.func,
  setFilters: PropTypes.func,
};

export {TempListPageView};
