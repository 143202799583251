/**
 * @format
 */
import * as Yup from 'yup';
import {phoneRegex} from 'globalValues';

export const ceTempSchema = {
  firstName: Yup.string().required('Required.'),
  lastName: Yup.string().required('Required.'),
  email: Yup.string()
    .email('Invalid email address.')
    .required('Required.'),
  phone: Yup.string()
    .matches(phoneRegex, 'Invalid phone number.')
    .required('Required.'),
  currentTitle: Yup.string().required('Required.'),
  skills: Yup.array(
    Yup.object({
      yearsOfExperience: Yup.string(),
      skillName: Yup.string(),
    }),
  ),
  street: Yup.string(),
  city: Yup.string(),
  state: Yup.string(),
  zip: Yup.number()
    .positive()
    .nullable(true),
  country: Yup.string(),
  // status: Yup.string().required('Required.'),
  // candidateStatus: Yup.string().required('Required.'),
  overallExperience: Yup.number().required('Required.'),
  resumeDoc: Yup.mixed().required('Required.'),
  contractDoc: Yup.mixed(),
  otherDocs: Yup.mixed(),
};

export const statusOptionList = [
  {
    value: 'Immediate',
    label: 'Immediate',
  },
  {
    value: '7 days',
    label: '7 days',
  },
  {
    value: '15 days',
    label: '15 days',
  },
  {
    value: '30 days',
    label: '30 days',
  },
  {
    value: '45 days',
    label: '45 days',
  },
  {
    value: '60 days',
    label: '60 days',
  },
  {
    value: '90 days',
    label: '90 days',
  },
];

export const candidateStatusOptionList = [
  {
    value: 'Applied',
    label: 'Applied',
  },
  {
    value: 'Added',
    label: 'Added',
  },
  {
    value: 'Called but no response',
    label: 'Called but no response',
  },
  {
    value: 'Not Interested',
    label: 'Not Interested',
  },

  {
    value: 'Interested Candidate',
    label: 'Interested Candidate',
  },
  {
    value: 'Initial Discussion',
    label: 'Initial Discussion',
  },
  {
    value: 'Rejected in Initial Discussion',
    label: 'Rejected in Initial Discussion',
  },
  {
    value: 'Client interview - Round 1',
    label: 'Client interview - Round 1',
  },
  {
    value: 'Rejected in Client Interview - Round 1',
    label: 'Rejected in Client Interview - Round 1',
  },
  {
    value: 'Client interview - Round 2',
    label: 'Client interview - Round 2',
  },
  {
    value: 'Rejected in Client Interview - Round 2',
    label: 'Rejected in Client Interview - Round 2',
  },
  {
    value: 'Final Negotiation',
    label: 'Final Negotiation',
  },
  {
    value: 'Offered',
    label: 'Offered',
  },
  {
    value: 'Joined',
    label: 'Joined',
  },
  {
    value: 'Offer Declined',
    label: 'Offer Declined',
  },
];

export const sourceTypeOptionList = [
  {
    value: 'Agency',
    label: 'Agency',
  },
  {
    value: 'HiringHQ',
    label: 'HiringHQ',
  },
  {
    value: 'Recruiter',
    label: 'Recruiter',
  },
  {
    value: 'Internal',
    label: 'Internal',
  },
  {
    value: 'naukri',
    label: 'Naukri',
  },
];
