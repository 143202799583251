/** @format */


import * as React from 'react';
import {
  Button,
  Popover,
  Typography,
  List,
  ListItem,
  ListItemButton,
} from '@mui/material';
import {Link as RouterLink} from 'react-router-dom';

const DropdownList = (props) => {
  const {setShowComponent} = props;
  
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedOption, setSelectedOption] = React.useState('');
  const options = [
    {label: 'Add Candidate', link: '/candidates/add'},
    {label: 'Bulk Upload', link: '/candidates/bulk_upload'},
    {label: 'Error logs', link: '/candidate/logs'},
    {label: 'Company job page', link: '/company/concinnity-media-technologies'},
    {label:  'Advanced Search'},
   
  ];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (option,) => {
    if (option.label === 'Advanced Search') {
      // Show a log message or perform any other action for "Advanced Search"
      console.log('Displaying log message for Advanced Search');
      setSelectedOption(option.label);
    } else if (typeof option === 'object' && option.link) {
      
      setSelectedOption(option.label);
      setAnchorEl(null); // Close the dropdown
      
    }
  };
  
  const buttonStyle = {
    color: 'white',
    background: '#1B55E2', // This sets the background color to the "info" color
  };

  return (
    <div>
      <Button
        aria-controls='dropdown-menu'
        aria-haspopup='true'
        onClick={handleClick}
        variant='outlined'
        style={buttonStyle}
        fullWidth>
        {selectedOption || 'options'}
      </Button>
      <Popover
        id='dropdown-menu'
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        <List
          style={{
            background: '#fff',
            fontSize: '12px',
            fontWeight: 700,
            color: '#888ea8',
            padding: '0 ,0 ',
          }}>
          {options.map((option) => (
            <ListItem key={option.label}>
              <ListItemButton onClick={() => handleOptionClick(option)}>
                {typeof option === 'object' ? (
                  <RouterLink to={option.link}>{option.label}</RouterLink>
                ) : (
                  <Typography variant='inherit'>{option}</Typography>
                )}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Popover>
    </div>
  );
};

export default DropdownList;
