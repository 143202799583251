/** @format */

import React, {useState} from 'react';
import {push} from 'react-router-redux';
import axios from 'axios';
import {Button, Typography, TextField} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
const API_URL = process.env.REACT_APP_API_URL;
const BulkUploadCv = (props) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const {status, sourceType} = props;
  const url = `${API_URL}documents/bulkcvupload`;
  const {firstName, lastName} = props.user;
  const userName = `${firstName} ${lastName}`;
  const userId = props.user._id;

  const handleFileChange = (event) => {
    setSelectedFiles(event.target.files[0]);
  };

  const handleUpload = () => {
    const formData = new FormData();
    formData.append('documents', selectedFiles);
    formData.append('userName', userName);
    formData.append('userId', userId);
    formData.append('sourceType', sourceType[1]?._id);
    formData.append('statusName', status[1]?._id);
    props.bulkCvUpload(formData);
  };

  return (
    <>
      <div className='layout-top-spacing'>
        <div className='row layout-spacing'>
          <div className='col-lg-12'>
            <div className='statbox widget box box-shadow'>
              <div
                className='widget-header'
                style={{backgroundColor: '#1B55E2'}}>
                <div className='row'>
                  <div className='col-xl-8 col-md-8 col-sm-8 col-8'>
                    <h4 style={{color: 'white'}}>Bulk CV upload</h4>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  padding: '20px',
                }}>
                <Typography variant='h6' gutterBottom>
                  Select a zip file for bulk upload
                </Typography>
                <input
                  type='file'
                  accept='.zip'
                  multiple
                  onChange={handleFileChange}
                  style={{marginBottom: '20px', width: '300px', height: '40px'}} 
                />
                <Button
                  variant='contained'
                  color='primary'
                  style={{color: '#fff'}}
                  startIcon={<CloudUploadIcon />}
                  onClick={handleUpload}>
                  Upload
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export {BulkUploadCv};
