/** @format */

import React, {useEffect, useState} from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
const API_URL = process.env.REACT_APP_API_URL;
function ElasticSearchPage(props) {
  const {showComponent, setShowComponent} = props;
  const [open, setOpen] = useState(true);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    skills: '',
    email: '',
    experience: '',
    candidateStatus: '',
    page: 1,
    pageSize: 10,
  });

  const handleClose = () => {
    setOpen(false);
    setShowComponent(false);
  };
  const handleInputChange = (event) => {
    const {name, value} = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior (page refresh)
    const apiUrl = `${API_URL}candidates/searchElastic?firstName=${formData.firstName}&lastName=${formData.lastName}&skills=${formData.skills}&page=${formData.page}&pageSize=${formData.pageSize}`;

    console.log('Form Data:', formData);
    props.fetchAllCandidates(apiUrl);
    setOpen(false);
    setShowComponent(false);
    // Make an HTTP GET request to the constructed URL
    // axios
    //   .get(apiUrl)
    //   .then((response) => {
    //     // Handle the response data as needed
    //     console.log('API Response:', response.data);

    //     // Close the dialog or perform other actions as needed

    //   })
    //   .catch((error) => {
    //     // Handle any errors that occur during the request
    //     console.error('API Error:', error);
    //   });
  };
  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth='lg'>
        <form onSubmit={handleSubmit}>
          <div className='work-experience' style={{width: '600px'}}>
            <div className='widget-content col-md-12 widget-content-area sm-margin-left'>
              <h3 className=''>Filter Candidates</h3>

              <TextField
                fullWidth
                label='First Name'
                variant='outlined'
                name='firstName'
                value={formData.firstName}
                onChange={handleInputChange}
              />
              <br />
              <br />

              <TextField
                fullWidth
                label='Last Name'
                variant='outlined'
                name='lastName'
                value={formData.lastName}
                onChange={handleInputChange}
              />
              <br />
              <br />
              <TextField
                fullWidth
                label='skills'
                variant='outlined'
                name='skills'
                value={formData.skills}
                onChange={handleInputChange}
              />
              <br />
              <br />
              <TextField
                fullWidth
                label='Experience'
                variant='outlined'
                name='experience'
                value={formData.experience}
                onChange={handleInputChange}
              />
              <br />
              <br />
              <TextField
                fullWidth
                label='Candidate Status'
                variant='outlined'
                name='candidateStatus'
                value={formData.candidateStatus}
                onChange={handleInputChange}
              />
              <br />
              <br />

              <DialogActions>
                {' '}
                <Button
                  variant='contained'
                  color='primary'
                  type='submit' // Trigger the handleSubmit function when the button is clicked
                >
                  Submit
                </Button>
                <Button
                  variant='outlined'
                  size='medium'
                  style={{color: 'blue', fontWeight: '700'}}
                  onClick={handleClose}>
                  Cancel
                </Button>
              </DialogActions>
            </div>
          </div>
        </form>
      </Dialog>
    </>
  );
}
export {ElasticSearchPage};
