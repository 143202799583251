/**
 * @format
 */
import {push} from 'react-router-redux';
import getObject from 'lodash.get';

import {post, put} from 'api';
import {showNotification, showAlert} from 'globalValues';

import {TOGGLE_CE_TEMP_LOADING} from '../types';

const toggleCETempLoading = (value) => {
  return {
    type: TOGGLE_CE_TEMP_LOADING,
    value,
  };
};

const getDocWithFileId = (doc) => {
  if (doc.fileId) {
    return doc;
  }

  return {
    fileId: doc.id,
    name: doc.name,
  };
};

export const getFileIds = (result, returnInArray) => {
  const uploadedDocs = getObject(result, 'data.uploadedDocs', []);

  if (uploadedDocs.length) {
    const response = uploadedDocs.map((doc) => {
      return getDocWithFileId(doc);
    });

    return returnInArray ? response : getObject(response, '[0]', {});
  }

  return result;
};

export const uploadDocuments = async (docs) => {
  const docsPromises = Object.values(docs).map((subDocs) => {
    const formData = new FormData();

    if (subDocs.length || Array.isArray(subDocs)) {
      const fileId = getObject(subDocs, '[0].fileId');

      if (fileId || !subDocs.length) {
        return Promise.resolve(subDocs);
      }

      [...subDocs].forEach((subDoc) => {
        formData.append('documents', subDoc);
      });
    } else {
      if (subDocs.fileId) {
        return Promise.resolve(subDocs);
      }

      formData.append('documents', subDocs);
    }

    return post({
      url: 'documents/upload',
      data: formData,
    });
  });

  const result = await Promise.all(docsPromises);

  return {
    resumeDoc: getFileIds(result[0]),
    contractDoc: getFileIds(result[1]),
    otherDocs: getFileIds(result[2], true),
  };
};

export const addStatus = async (status) => {
  const updatedParams = {
    statusName: status,
  };
  const results = await post({
    url: 'candidateStatus/add',
    data: updatedParams,
  });
  console.log(results, '89');

  // return results
};
export const editTemp = (params, id) => {
  return async (dispatch) => {
    dispatch(toggleCETempLoading(true));

    try {
      const uploadedDocs = await uploadDocuments({
        resumeDoc: params.resumeDoc,
      });

      const updatedParams = {
        ...params,
        ...uploadedDocs,
      };

      const result = await put({
        url: `candidates/candidate/${id}`,
        data: updatedParams,
      });

      if (result.success) {
        showNotification({
          message: 'Candidate Successfully Edited.',
        });
        //  dispatch(push('/temps'));
        dispatch(push(`/candidates/candidate/${id}/view`));
      } else {
        const message = getObject(result, 'message', '');

        showAlert({
          type: 'error',
          title: 'Error!',
          message: message || 'Please check your fields',
        });
      }

      dispatch(toggleCETempLoading(false));
    } catch (err) {
      showAlert({
        type: 'info',
        title: 'Sorry!',
        message: 'Something went wrong. Please try again later.',
      });
      dispatch(toggleCETempLoading(false));
    }
  };
};

export const editCandidateStatus = (params, id, jobId) => {
  return async (dispatch) => {
    dispatch(toggleCETempLoading(true));

    try {
      const uploadedDocs = await uploadDocuments({
        resumeDoc: params.resumeDoc,
      });

      const updatedParams = {
        ...params,
        ...uploadedDocs,
      };

      const result = await put({
        url: `candidates/candidate/${id}`,
        data: updatedParams,
      });

      if (result.success) {
        showNotification({
          message: 'Status Successfully Updated.',
        });
        //  dispatch(push('/temps'));
        dispatch(push(`/jobs/job/${jobId}/view`));
      } else {
        const message = getObject(result, 'message', '');

        showAlert({
          type: 'error',
          title: 'Error!',
          message: message || 'Please check your fields',
        });
      }

      dispatch(toggleCETempLoading(false));
    } catch (err) {
      showAlert({
        type: 'info',
        title: 'Sorry!',
        message: 'Something went wrong. Please try again later.',
      });
      dispatch(toggleCETempLoading(false));
    }
  };
};

export const editTempStatus = (params, id) => {
  return async (dispatch) => {
    dispatch(toggleCETempLoading(true));

    try {
      const uploadedDocs = await uploadDocuments({
        resumeDoc: params.resumeDoc,
      });

      const updatedParams = {
        ...params,
        ...uploadedDocs,
      };

      const result = await put({
        url: `candidates/candidate/${id}`,
        data: updatedParams,
      });

      if (result.success) {
        showNotification({
          message: 'Candidate Successfully Edited.',
        });
        dispatch(push(`candidates/candidate/${id}/view`));
      } else {
        const message = getObject(result, 'message', '');

        showAlert({
          type: 'error',
          title: 'Error!',
          message: message || 'Please check your fields',
        });
      }

      dispatch(toggleCETempLoading(false));
    } catch (err) {
      showAlert({
        type: 'info',
        title: 'Sorry!',
        message: 'Something went wrong. Please try again later.',
      });
      dispatch(toggleCETempLoading(false));
    }
  };
};

export const createTemp = (params) => {
  return async (dispatch) => {
    dispatch(toggleCETempLoading(true));

    try {
      // await addStatus(params.candidateStatus)
      const uploadedDocs = await uploadDocuments({
        resumeDoc: params.resumeDoc,
      });

      const updatedParams = {
        ...params,
        ...uploadedDocs,
      };

      const result = await post({
        url: 'candidates/add',
        data: updatedParams,
      });

      if (result.success) {
        showNotification({
          message: 'Candidate Successfully Added.',
        });
        dispatch(push('/candidates'));
      } else {
        const message = getObject(result, 'message', '');

        showAlert({
          type: 'error',
          title: 'Error!',
          message: message || 'Please check your fields',
        });
      }

      dispatch(toggleCETempLoading(false));
    } catch (err) {
      showAlert({
        type: 'info',
        title: 'Sorry!',
        message: 'Something went wrong. Please try again later.',
      });
      dispatch(toggleCETempLoading(false));
    }
  };
};
